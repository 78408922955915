import request from '@/utils/request'
const isProd = process.env.NODE_ENV === 'production'

const ansApi = {
	getList: (isProd ? '' : '/api') + '/newAdmin.php?c=Operate&a=dtList',
	getDetail: (isProd ? '' : '/api') + '/newAdmin.php?c=Operate&a=dtInfo',
	_edit: (isProd ? '' : '/api') + '/newAdmin.php?c=Operate&a=editDt',
	_add: (isProd ? '' : '/api') + '/newAdmin.php?c=Operate&a=addDt',
	_del: (isProd ? '' : '/api') + '/newAdmin.php?c=Operate&a=delTopic',
	_forbid: (isProd ? '' : '/api') + '/newAdmin.php?c=Operate&a=openDt',
	getQusList: (isProd ? '' : '/api') + '/newAdmin.php?c=Operate&a=topicQuestions',
	addQus: (isProd ? '' : '/api') + '/newAdmin.php?c=Operate&a=addEditQuestion',
	delQus: (isProd ? '' : '/api') + '/newAdmin.php?c=Operate&a=delQuestion',
	getJoinList :(isProd ? '' : '/api') + '/newAdmin.php?c=Operate&a=joinLst',
	getRankList :(isProd ? '' : '/api') + '/newAdmin.php?c=Operate&a=leaderBoardDetail',
}


/*排行*/
export function getRankList(parameter) {
	return request({
		url: ansApi.getRankList,
		method: 'post',
		data: parameter
	})
}


/*参与列表*/
export function getJoinList(parameter) {
	return request({
		url: ansApi.getJoinList,
		method: 'post',
		data: parameter
	})
}


/*删除题目*/
export function delQus(parameter) {
	return request({
		url: ansApi.delQus,
		method: 'post',
		data: parameter
	})
}

/*编辑题目*/
export function addQus(parameter) {
	return request({
		url: ansApi.addQus,
		method: 'post',
		data: parameter
	})
}
/*题库列表*/
export function getQusList(parameter) {
	return request({
		url: ansApi.getQusList,
		method: 'post',
		data: parameter
	})
}

/*活动列表*/
export function getList(parameter) {
	return request({
		url: ansApi.getList,
		method: 'post',
		data: parameter
	})
}

/*活动详情*/
export function getDetail(parameter) {
	return request({
		url: ansApi.getDetail,
		method: 'post',
		data: parameter
	})
}

/*新增*/
export function _add(parameter) {
	return request({
		url: ansApi._add,
		method: 'post',
		data: parameter
	})
}

/*编辑*/
export function _edit(parameter) {
	return request({
		url: ansApi._edit,
		method: 'post',
		data: parameter
	})
}

/*编辑*/
export function _del(parameter) {
	return request({
		url: ansApi._del,
		method: 'post',
		data: parameter
	})
}
//开启关闭
export function _forbid(parameter) {
	return request({
		url: ansApi._forbid,
		method: 'post',
		data: parameter
	})
}