<template>
	<page-header-wrapper>
		<template slot="extra">
			<a-button type="" @click="exportExcel">批量导入</a-button>
		    <a-button type="primary" @click="add">新增题目</a-button>
		</template>
		<a-card :bordered="false">
			<div class="table-page-search-wrapper">
				<a-form layout="inline">
					<a-row :gutter="24">
						<a-col :md="12" :sm="24">
							
						</a-col>
						
						<a-col :md="8" :sm="24">
						
						</a-col>
						<a-col :md="4" :sm="24">
							
						</a-col>
					</a-row>
				</a-form>
			</div>

			<s-table ref="table" size="default" :rowKey="(record) => record.id" :columns="columns" :data="loadData" :scroll="{x:'100%'}" class="qbTable" showPagination="auto">
				<span slot="question" slot-scope="question,record"> 
					<span v-if="record.type==1" class="correct">[单选]</span>
					<span v-else-if="record.type==2" class="correct">[多选]</span>
					<span v-else-if="record.type==3" class="correct">[判断]</span>
					<span v-else-if="record.type==4" class="correct">[问答]</span>
					<span>{{question}}</span>
				</span>
				<span slot="answer" slot-scope="type,record">
					<!-- 单选/多选/判断 -->
					<div v-if="record.infos.length>0">
						<div v-for="(item,index) in record.infos" :key="index">
							<span>{{alphabet[index]}}.  {{item.option}}</span>
							<span v-if="item.is_true == 1" class="correct">[正确]</span>
						</div>
					</div>
					<div v-else>
						--
					</div>
				</span>
				<span slot="provide" slot-scope="provide">
					{{provide||'-'}}
				</span>
				<span slot="action" slot-scope="text, record">
					<template>
						<a @click="handleEdit(record)">编辑</a>
						<a-divider type="vertical" />
						<a @click="handleDel(record)">删除</a>
					</template>
				</span>
			</s-table>
			
			<!-- 弹窗 -->
			<a-modal
			    :title="qid?'编辑题目':'新增题目'"
			    :visible="visible"
			    :confirm-loading="confirmLoading"
			    width="460px" class="modal_foot_none" :closable="false">
			    <div>
			        <a-form :form="form" :label-col="{ span: 4 }" :wrapper-col="{ span: 20 }" @submit="handleSubmit" class="qbForm">
			            <a-form-item label="类型">
							<a-radio-group name="radioGroup" @change="change" :disabled="qid?true:false"
								v-decorator="['type', { rules: [{ required: true, message: '请选择类型!' }] }]">
							    <a-radio value="1">单选</a-radio>
							    <a-radio value="2">多选</a-radio>
							    <a-radio value="3">判断</a-radio>
							    <a-radio value="4">问答</a-radio>
							</a-radio-group>
			            </a-form-item>
						<a-form-item label="题目">
							<a-input v-decorator="['question', { rules: [{ required: true, message: '请输入题目!' }] }]" placeholder="请输入题目" />
						</a-form-item>
						<div class="ansList" v-if="type==1">
							<div class="answer_l">
								<a-form-item :label="'答案'+(index+1)" v-for="(item,index) in answerRadioData" :key="index">
									<a-input v-decorator="['answer'+index, { rules: [] }]" placeholder="请输入题目" :data-id="item.id" @change="getAnswer" 
									style="width:95%;margin-left:16px;" />
								</a-form-item>
							</div>
							<div class="answer_r">
								<a-form-item>
									<div>
										<a-radio-group name="radioGroup"  v-decorator="['option']" @change="getOption" >
											<div>
												<a-radio :style="radioStyle" :value="index" 
												:disabled="item.option?false:true" v-for="(item,index) in answerRadioData" :key="index">
													{{item.is_true==1?'正确':'错误'}}
												</a-radio>
											</div>
										</a-radio-group>
									</div>
								</a-form-item>
							</div>
						</div>
						<div class="ansList" v-else-if="type==2">
							<div class="answer_l">
								<a-form-item :label="'答案'+(index+1)" v-for="(item,index) in answerCheckBoxData" :key="index">
									<a-input v-decorator="['answer'+index, { rules: [] }]" placeholder="请输入题目" @change="getChkAnswer" 
									style="width:95%;margin-left:16px;" />
								</a-form-item>
							</div>
							<div class="answer_r">
								<a-form-item>
									<div>
										<a-checkbox-group name="radioGroup" v-decorator="['option']" @change="getChkOption" >
											<div>
												<a-checkbox :style="radioStyle" :value="index" 
												:disabled="item.option?false:true" v-for="(item,index) in answerCheckBoxData" :key="index">
													{{item.is_true==1?'正确':'错误'}}
												</a-checkbox>
											</div>
										</a-checkbox-group>
									</div>
								</a-form-item>
							</div>
						</div>
						<div class="ansList" v-if="type==3">
							<div class="answer_l">
								<a-form-item :label="'答案'+(index+1)" v-for="(item,index) in answerJudgeData" :key="index">
									<a-input v-decorator="['answer'+index, { rules: [] }]" placeholder="请输入题目" @change="getJudeAnswer" 
									style="width:95%;margin-left:16px;" />
								</a-form-item>
							</div>
							<div class="answer_r">
								<a-form-item>
									<div>
										<a-radio-group name="radioGroup"  v-decorator="['option']" @change="getJudeOption">
											<div>
												<a-radio :style="radioStyle" :value="index" 
												:disabled="item.option?false:true" v-for="(item,index) in answerJudgeData" :key="index">
													{{item.is_true==1?'正确':'错误'}}
												</a-radio>
											</div>
										</a-radio-group>
									</div>
								</a-form-item>
							</div>
						</div>
						<a-form-item label="提供者">
							<a-input v-decorator="['provide']" placeholder="请输入提供者"/>
						</a-form-item>
			            <a-form-item :wrapper-col="{ span: 24 }" style="text-align: center;">
			                <a-button type="primary" html-type="submit">
			                    确定
			                </a-button>
			                <a-button type="default" style="margin-left: 10px;" @click="resetForm">
			                    取消
			                </a-button>
			            </a-form-item>
			        </a-form>
			    </div>
			</a-modal>
			
			<a-modal
			    title="批量导入"
			    :visible="visible1"
			    width="460px" class="modal_foot_none" :closable="false">
			    <div>
					<a-spin tip="正在导入..." :spinning="spinning">
						<div>
							<a-radio-group name="radioGroup"  v-model="qusType" @change="getType">
								<a-radio :value="1">单选</a-radio>
								<a-radio :value="2">多选</a-radio>
								<a-radio :value="3">判断</a-radio>
								<a-radio :value="4">问答</a-radio>
							</a-radio-group>
						</div>
						<div id="model">
							<a-icon type="exclamation-circle" /> 点击下载导入模板 <span @click="download">下载模板</span>
						</div>
						<div id="export_bottom">
							<a-button @click="visible1=false">取消</a-button>
							<a-upload :action="qusUrl" accept=".xls,.xlsx" class="excelUpload" @change="handleChange" :data="dataObj">
								<a-button type="primary">导入</a-button>
							</a-upload>
						</div>
					</a-spin>
			    </div>
			</a-modal>
		</a-card>
	</page-header-wrapper>
</template>

<script>
	import moment from 'moment'
	import { Modal,message } from 'ant-design-vue'
	import {
		STable,
		Ellipsis
	} from '@/components'
	import {
		getQusList,
		addQus,
		delQus
	} from '@/api/answer'
	import { prefix,getSource,getUrlKey,imgUpload,qusUrl,decrypt } from '@/utils'

	const columns = [
		{
			title: '题目',
			dataIndex:'question',
			scopedSlots: {
				customRender: 'question'
			},
			align:'center',
			width:'65%'
		},
		{
			title: '答案',
			dataIndex:'infos',
			scopedSlots: {
				customRender: 'answer'
			},
			align:'center',
			width:'35%'
		},
		{
			title: '提供者',
			dataIndex:'provide',
			scopedSlots: {
				customRender: 'provide'
			},
			width:'120px',
			align:'center'
		},
		{
			title: '操作',
			dataIndex: 'action',
			width:'120px',
			align:'center',
			scopedSlots: {
				customRender: 'action'
			}
		}
	]

	export default {
		name: '',
		components: {
			STable,
			Ellipsis
		},
		data() {
			this.columns = columns
			return {
				visible: false,
				visible1:false,
				advanced: false,
				imgUpload:imgUpload,
				qusUrl:qusUrl,
				// 查询参数
				queryParam: {
					tid:getUrlKey('id')
				},
				// 加载数据方法 必须为 Promise 对象
				loadData: parameter => {
					//console.log('parameter',parameter)
					const requestParameters = Object.assign({}, parameter, this.queryParam)
					//console.log('requestParameters',requestParameters)
					return getQusList(requestParameters)
						.then(res => {
							return res.data
						})
				},
				photo: [],
				modalVisible: false, //回复弹窗
				reason: '', //审核理由
				info: '',   //详情
				id: '',     //列表id
				status:'',  //2 通过  3 拒绝
				isChecked:false,
				prefix:prefix, 
				text1:['户主身份证','产权证明'],
				text2:['买方身份证','买方身份证'],
				active:0,    //全部
				alphabet: [  //字母表
					"A",
					"B",
					"C",
					"D",
					"E",
					"F",
					"G",
					"H",
					"I",
					"J",
					"K",
					"L",
					"M",
					"N",
					"O",
					"P",
					"Q",
					"R",
					"S",
					"T",
					"U",
					"V",
					"W",
					"X",
					"Y",
					"Z"
				],
				confirmLoading: false,
				form:this.$form.createForm(this),
				type:'1',   //题目类型
				answerRadioData: [
					//单选数据
					{
						id: "",
						option: "",
						is_true:0,
					},
					{
						id: "",
						option: "",
						is_true:0,
					},
					{
						id: "",
						option: "",
						is_true:0,
					},
					{
						id: "",
						option: "",
						is_true:0,
					},
					{
						id: "",
						option: "",
						is_true:0,
					}
				],
				answerCheckBoxData: [
					  //多选数据
					  {
						id: "",
						option: "",
						is_true:0
					  },
					  {
						id: "",
						option: "",
						is_true:0
					  },
					  {
						id: "",
						option: "",
						is_true:0
					  },
					  {
						id: "",
						option: "",
						is_true:0
					  }
				],
				answerJudgeData: [
					 //判断数据
					{
						id: "",
						option: "",
						is_true:0
					},
					{
						id: "",
						option: "",
						is_true:0
					}
				],
				radioStyle: {
				    display: 'block',
					width:'80px'
				},
				qid:'',  //题目id
				qType:1,
				qusType:1,
				question:'',
				defaultValue:'',
				spinning:false,
				dataObj:{
					tid:getUrlKey('id'),
					type:1,
                    token:localStorage.getItem('Access-Token')
				}
			}
		},
		created(){
			this.tid = getUrlKey('id')||''
		},
		methods: {
			getSource:getSource,
			exportExcel(){
				this.visible1 = true
			},
			getType(e){
				this.dataObj.type = e.target.value 
			},
			download(){
				if(this.qusType==1){
					window.open('https://wapi.hfwater.cn/public/question/single.xls')
				}else if(this.qusType==2){
					window.open('https://wapi.hfwater.cn/public/question/multiple.xls')
				}else if(this.qusType==3){
					window.open('https://wapi.hfwater.cn/public/question/jugement.xls')
				}else if(this.qusType==4){
					window.open('https://wapi.hfwater.cn/public/question/content.xls')
				}
			},
			handleChange(info) {
				const status = info.file.status;
				if(status == 'uploading'){
					this.spinning = true
				}
				if (status !== 'uploading') {
					console.log(info.file, info.fileList);
				}
				if(status == "removed"){
				    this.form.cover = ''
				}
				if (status === 'done') {
					this.spinning = false
					this.$message.success('导入成功')
					this.visible1 = false
					this.$refs.table.refresh(true)
				} else if (status === 'error') {
				    this.$message.error(`${info.file.name} 上传失败.`);
				}
			},
			change(e){
				let type = e.target.value
				this.type = type
				this.form.resetFields()
				if(type==1){
					console.log(this.qType,this.answerRadioData)
					if(this.qType==1&&this.answerRadioData[0].id){
						for(let i in this.answerRadioData){
							this.form.setFieldsValue({
								['answer'+i]:this.answerRadioData[i].option
							})
						}
						this.form.setFieldsValue({
							question:this.question
						})
					}else{
						this.answerRadioData.map(item=>{
							item.is_true = 2
							item.option = ''
						})
					}
				}else if(type==2){
					if(this.qType==2&&this.answerCheckBoxData[0].id){
						for(let i in this.answerCheckBoxData){
							this.form.setFieldsValue({
								['answer'+i]:this.answerCheckBoxData[i].option
							})
						}
						this.form.setFieldsValue({
							question:this.question
						})
					}else{
						this.answerCheckBoxData.map(item=>{
							item.is_true = 2
							item.option = ''
						})
					}
				}else if(type==3){
					if(this.qType==3&&this.answerJudgeData[0].id){
						for(let i in this.answerJudgeData){
							this.form.setFieldsValue({
								['answer'+i]:this.answerJudgeData[i].option
							})
						}
						this.form.setFieldsValue({
							question:this.question
						})
					}else{
						this.answerJudgeData.map(item=>{
							item.is_true = 2
							item.option = ''
						})
					}
				}
			},
			getAnswer(e){   //单选
				console.log('e',e,e.target.id,e.target.value)
				let index = Number(e.target.id.replace(/answer/g,''))
				let answer = e.target.value
				console.log('index',index,answer)
				//this.$set(this.answerRadioData,index,{option:answer})
				let temp = this.answerRadioData
				temp[index].option = answer
				this.answerRadioData = []
				this.answerRadioData = temp
			},
			getOption(e){   //单选
				console.log('e',e.target.value)
				this.answerRadioData.map(item =>{
					return item.is_true = 2 
				})
				this.answerRadioData[e.target.value].is_true=1
			},
			getChkAnswer(e){ //多选
				console.log('e',e.target.id,e.target.value)
				let index = Number(e.target.id.replace(/answer/g,''))
				let answer = e.target.value
				//this.$set(this.answerCheckBoxData,index,{id:'',option:answer})
				let temp = this.answerCheckBoxData
				temp[index].option = answer
				this.answerCheckBoxData = []
				this.answerCheckBoxData = temp
			},
			getChkOption(e){   //多选
				console.log('e',e)
				this.answerCheckBoxData.map(item =>{
					return item.is_true = 0 
				})
				e.map(item=>{
					this.answerCheckBoxData[item].is_true = 1
				})
			},
			getJudeAnswer(e){  //判断
				console.log('e',e.target.id,e.target.value)
				let index = Number(e.target.id.replace(/answer/g,''))
				let answer = e.target.value
				console.log('index',index,answer)
				//this.$set(this.answerJudgeData,index,{id:'',option:answer})
				let temp = this.answerJudgeData
				temp[index].option = answer
				this.answerJudgeData = []
				this.answerJudgeData = temp
			},
			getJudeOption(e){  //判断
				console.log('e',e.target.value)
				this.answerJudgeData.map(item =>{
					return item.is_true = 0 
				})
				this.answerJudgeData[e.target.value].is_true=1
			},
			reply() { //回复弹窗打开
				this.modalVisible = true
			},
			reset() { //重置
				this.queryParam.time = null
				this.queryParam.startTime = ''
				this.queryParam.endTime = ''
				this.queryParam.auditStatus = '0'
				this.queryParam.keyword = ''
				this.queryParam.keyType = '1'
				this.$refs.table.refresh(true)
			},
			resetForm(){
				this.form.setFieldsValue({
					type:'1',
					question:''
				})
				this.qid = ''
				this.visible = false
			},
			goBanks(record){   //题库
				this.$router.push({path:'/questionBank?id='+record.id})
			},
			handleEdit(data) { //编辑
				console.log('data',data)
				this.visible = true
				this.qType = data.type
				this.qid = data.id
				this.question = data.question
				
				this.$nextTick(()=>{
					setTimeout(_=>{
						this.form.setFieldsValue({
							type:data.type,
							question:data.question,
							provide:data.provide
						})
						if(data.type==1){
							this.answerRadioData = data.infos
							for(let i in data.infos){
								this.form.setFieldsValue({
									['answer'+i]:data.infos[i].option
								})
								if(data.infos[i].is_true==1){
									this.form.setFieldsValue({
										option:Number(i)
									})
								}
							}
						}else if(data.type==2){
							this.answerCheckBoxData = data.infos
							for(let i in data.infos){
								this.form.setFieldsValue({
									['answer'+i]:data.infos[i].option
								})
							}
						}else if(data.type==3){
							this.answerJudgeData = data.infos
							for(let i in data.infos){
								this.form.setFieldsValue({
									['answer'+i]:data.infos[i].option
								})
							}
						}
					},500)
				})
				
			},
			add(){  //新增题目
				this.visible = true
				this.$nextTick(()=>{
				    this.form.setFieldsValue({
						type:'1'
					}) 
				})
			},
			handleWatch(record){
				this.id = record.id
				this.getDetail(record.id)
				this.isChecked = true;
				this.visible = true
			},
			handleDel(record) { 
				let params = {
					qid:record.id
				}
				let that = this
				this.$confirm({
					content:'确认删除吗?',
					onOk(){
						delQus(params).then(res =>{
							if(res.code==200){
								that.$message.success(res.msg)
								that.$refs.table.refresh(true)
							}else{
								that.$message.error(res.msg)
							}
						}).catch(res =>{
							
						})
					},
					onCancel(){
						
					}
				})
			},
			getDetail(id) { //详情
				let params = {
					id: id
				}
				getTransferInfoJmh(params).then(res => {
					//console.log('res',res)
					this.info = res.data;
					var arr = Object.entries(res.data.attachment);
					console.log(arr)
					this.photo = arr;
					this.$previewRefresh()
				})
			},
			onClose(status) { //关闭右侧弹出层   2 通过  3 拒绝
				console.log('status', status)
				this.status = status;
				if (status == 2 || status == 3) {
					this.modalVisible = true;
				} else {
					this.visible = false
				}
			},
			handleSubmit(e) {
			    e.preventDefault();
			    let that = this
			    this.form.validateFields((err, values) => {
			        console.log('err',err,'values',values)
			        if (!err) {
						console.log('Received values of form: ', values);
						let options = null;
						if(values.type==1){
							options =  this.answerRadioData
						}else if(values.type==2){
							options =  this.answerCheckBoxData
						}else if(values.type==3){
							options =  this.answerJudgeData
						}
						
						if(values.type<4){
							let temp = [];
							options.map(item=>{
								if(item.option){
									temp.push({id:item.id||'',option:item.option,is_true:item.is_true||2})
								}
							})
							options = temp
							console.log('options===',options)
							if(options.length<2){
								this.$message.error('请至少输入2个选项')
								return
							}
						}
						//return
						let params = {
							tid:this.tid,
							qid:this.qid,
							type:values.type,
							provide:values.provide,
							question:values.question,
							options:JSON.stringify(options)
			            }
			            addQus(params).then(res =>{
							if(res.code==200){
								that.$refs.table.refresh(true)
								that.visible = false
							}
						}).catch(res =>{
							
						})
			        }else{
			
			        }
			    });
			},
		},
		filters: {
			time(t) {
				if (t) {
					return moment(parseInt(t) * 1000).format('YYYY/MM/DD HH:mm:mm')
				} else {
					return t
				}
			},
			statusFilter(type) {
				return statusMap[type].text
			},
			statusTypeFilter(type) {
				return statusMap[type].status
			}
		}
	}
</script>


<style scoped>
.tab_main{}
.tab_main_title{margin-right:10px;}
.tab_main_block{margin-right:10px;cursor:pointer;}
.tab_main_act{color:#1890ff;}
.content_line1{margin-bottom: 15px;}
.content_line1_title{font-weight: 600;font-size: 16px;color:#000;}
.content_line2{}
.content_line2_data{}
.content_line2_data i{font-style: normal;color: #f50;margin:0 5px;}
.correct{color:#1890ff;margin-left: 10px;margin-right:10px;}
.qbTable >>> .ant-table-row td:nth-child(1){text-align: left!important;}
.qbTable >>> .ant-table-row td:nth-child(2){text-align: left!important;}
.ansList{display: flex;}
.answer_r{}
.answer_l{width: 80%;}
.answer_r >>> .ant-radio-wrapper{    
	box-sizing: border-box;margin: 0;padding: 0;color: rgba(0, 0, 0, 0.65);
    font-size: 14px; font-variant: tabular-nums;line-height: 1.5;list-style: none;margin-bottom: 24px;
    vertical-align: top;height: 40px;line-height: 40px;margin-left: 20px;
}
.answer_r >>> .ant-checkbox-wrapper{    
	box-sizing: border-box;margin: 0;padding: 0;color: rgba(0, 0, 0, 0.65);
    font-size: 14px; font-variant: tabular-nums;line-height: 1.5;list-style: none;margin-bottom: 24px;
    vertical-align: top;height: 40px;line-height: 40px;margin-left: 20px;
}
.answer_r >>> .ant-radio-wrapper:last-child{
	margin-bottom: 0px;
}
.answer_r >>> .ant-checkbox-wrapper:last-child{
	margin-bottom: 0px;
}
.qbForm >>> .ant-form-item-label{text-align: left!important;}
#model{
	margin:20px 0px;
	display: flex;
	align-items: center;
}
#model span{color: #40a9ff;margin-left: 10px;cursor: pointer;}
#model i{margin-right:10px;}
#export_bottom{
	display: flex;
	justify-content: space-around;
}
</style>
<style>
.excelUpload .ant-upload-list{display: none;}
</style>